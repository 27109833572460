:root {
  --bg-color: #fff;
  --bg-color2: rgb(7, 19, 33);
  --black: rgb(41, 41, 98);
  --accent: rgb(101, 118, 255);
  --white: #fff;
  //--accent2: #0085FF;
  //--accent3: #f0de36;
  //--darkaccent2: #a50f0e;
  --bs-gutter-x: 3rem;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #6b7989;
}

body {
  font-family: 'Spline Sans', sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.4;
  //background-color: var(--bg-color);
  margin: 0;
  padding: 0;
  background-color: var(--white);
}

.h1,
.h2 {
  font-size: 3rem;
}

.h3 {
  font-size: 2rem;
}

.h4 {
  font-size: 1.75rem;
}

.h5 {
  font-size: 1.5rem;
}

.h6 {
  font-size: 1.25rem;
}

img {
  max-width: 100%;
}

p {
  font-size: 1rem;
  line-height: 25.6px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 100%;
  position: relative;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width:1400px) {
    max-width: 1320px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }
}

.mainWrapper {
  header {
    padding: 0.5rem 0;

    @media (min-width: 992px) {
      padding: 1rem 0;
    }

    background-color: #fff;
    box-shadow: 0 1.125rem 2.25rem -1.188rem rgba(0, 0, 0, 0.4);
    position: relative;
    z-index: 2;

    .logo-wrapper {
      a {
        img {
          max-width: 2rem;

          @media (min-width: 992px) {
            max-width: 3rem;
          }
        }
      }
    }

    display: none;
  }

  .hero-section {
    padding: 5.5rem 0 0 0;
    position: relative;
    overflow: hidden;
    background: url(assets/dot-a-white.c0dd91a2.png) 50% 50% repeat var(--bg-color2);
    background-size: 20px 20px;

    &::before {
      content: "";
      position: absolute;
      height: 866px;
      width: 928px;
      top: 0;
      left: 50%;
      background-image: url(assets/blur-q.3f61b3b4.png);
      background-size: contain;
      background-position: 50%;
      background-repeat: no-repeat;
      pointer-events: none;
      margin-top: -20%;
      margin-left: 10%;
      transform: translateX(-50%);
    }

    &::after {
      content: "";
      position: absolute;
      height: 883px;
      width: 883px;
      top: 0;
      left: 50%;
      background-image: url(assets/blur-r.46587ad8.png);
      background-size: contain;
      background-position: 50%;
      background-repeat: no-repeat;
      pointer-events: none;
      margin-top: -10%;
      margin-left: -10%;
      transform: translateX(-50%);
    }

    @media (min-width: 768px) {
      padding: 6rem 0 0 0;
    }

    .container {
      .row {
        min-height: 50vh;

        @media (min-width: 992px) {
          align-items: center;
        }

        .hero-container {
          width: 100%;
          flex: 0 0 auto;
          text-align: center;

          @media (min-width: 992px) {
            width: 50%;
            padding: 0 0 1rem 0;
            text-align: left;
          }

          .headline-wrapper {
            position: relative;

            h1 {
              display: inline-block;
              padding: 0.625rem 1.25rem;
              margin-bottom: 1.25rem;
              border-radius: 500px;
              color: var(--black);
              font-size: 15px;
              font-weight: 400;
              background-color: var(--bg-color);
            }
          }
        }

        .sub-hero-section {
          .hero-section-wrapper {
            .hero-section-wrapper-content {
              h2 {
                font-size: calc(1.425rem + 2.1vw);
                line-height: 1.25;
                margin-bottom: 1rem;
                color: var(--black);
                font-weight: 600;
                color: var(--white);
                @media (min-width:992px) {
                  font-size: 3rem;
                }
              }

              p {
                margin-bottom: 1rem;
                color: var(--white);
                font-size: 1rem;
                line-height: 25.6px;
              }
            }

            .hero-sectiom-image {
              margin-bottom: 1rem;
            }

            .cta {
              a {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding: 0.625rem 1.5rem;
                font-size: 0.875rem;
                font-weight: 500;
                line-height: 1.5rem;
                color: var(--white);
                background-color: var(--accent);
                text-align: center;
                vertical-align: middle;
                user-select: none;
                border: 0.125rem solid var(--accent);
                text-decoration: none;
                border-radius: 10px;
                width: 200px;
                margin: 1rem 0 .625rem 0;

                img {
                  max-width: 1.3em;
                  margin-left: 0.5rem;
                }

                &:hover,
                &:focus {
                  //background-color: var(--darkaccent2);
                }
              }

              span {
                margin-top: .5rem;
                display: block;
                text-align: center;
                color: var(--accent);
                font-weight: 500;
                @media (min-width: 992px) {
                  margin-top: 1rem;
                  text-align: left;
                }

                font-size: 1rem;
              }
            }

            .review-badge {
              margin: 1rem 0;

              @media (min-width: 992px) {
                text-align: center;
              }
            }
          }
        }

        .congrats {
          max-width: 580px;
          width: 100%;
          background-color: #fff;
          border-radius: 18px;
          text-align: center;
          padding: 1rem;

          h1 {
            font-size: calc(1.35rem + 1.2vw);
            line-height: 1.25;
            color: var(--accent) !important;
            margin-bottom: .625rem;
          }

          h2 {
            color: var(--black);
            margin-bottom: 0.5rem;
            font-size: 1.25rem;
            line-height: 1.25;

            @media (min-width: 992px) {
              font-size: 2rem;
            }
          }

          a {
            width: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 0.9rem 1.5rem;
            font-size: 1.25rem;
            font-weight: 500;
            line-height: 1.5rem;
            color: var(--white);
            background-color: var(--accent);
            text-align: center;
            vertical-align: middle;
            user-select: none;
            border: 0.125rem solid var(--accent);
            text-decoration: none;
            border-radius: 10px;
            margin: 1rem 0 .625rem 0;

            &:hover,
            &:focus {
              //background-color: var(--darkaccent2);
            }
          }

          p {
            span {
              color: #0d1282;
              font-weight: bold;
            }
          }

          img {
            margin-top: 1rem;
          }
        }

        .question-section {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          flex: 0 0 auto;

          @media (min-width:992px) {
            width: 50%;
          }

          .headline-wrapper {
            width: 100%;
            text-align: center;

            h1 {
              display: inline-block;
              padding: 0.625rem 1.25rem;
              margin-bottom: 1.25rem;
              border-radius: 500px;
              color: var(--black);
              font-size: 15px;
              font-weight: 400;
              background-color: var(--bg-color);
            }
          }

          .question-wrapper {
            flex: auto;
            width: 100%;
            background-color: var(--white);
            border-radius: 8px;
            box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
            padding: 0.5rem;
            @media (min-width:768px) {
              max-width: 550px;
              margin: 0 auto;
              padding: 1rem;
            }
            .headline-wrapper {
              width: 100%;

              h1 {
                color: var(--accent);
                font-size: 1.6425rem;
                text-align: center;
                line-height: 1.2;
                font-weight: 500;
              }
            }

            .question-item {
              .question {
                position: relative;
                text-align: center;

                @media (min-width: 992px) {
                  padding: 0;
                  background-color: transparent;
                  box-shadow: none;
                  margin: 0;
                }

                border-radius: 18px;
                margin: 1rem auto;
                max-width: 580px;
                width: 100%;

                h2 {
                  color: var(--black);
                  font-size: 1.25rem;
                  text-align: center;
                  line-height: 1.5;
                  font-weight: 500;
                  margin-bottom: 1.25rem;
                  @media (min-width:768px) {
                    font-size: 25px;
                  }
                }

                a {
                  display: inline-flex;
                  width: 100%;
                  align-items: center;
                  justify-content: center;
                  padding: 0.625rem 1.5rem;
                  font-size: 0.875rem;
                  font-weight: 500;
                  line-height: 1.5rem;
                  color: var(--white);
                  text-align: center;
                  text-decoration: none;
                  vertical-align: middle;
                  cursor: pointer;
                  user-select: none;
                  border: 0.125rem solid #6576ff;
                  border-radius: 0.5rem;
                  background-color: var(--accent);
                  margin-bottom: .25rem;
                }
              }

            }

            h1 {
              color: #fff;
            }
          }
        }

        .card-section {
          margin-top: 2rem;
          position: relative;
          z-index: 5;
          padding: 0 1rem;

          @media (min-width: 992px) {
            width: 50%;
            padding: 0 1rem;
            margin: 0;
          }

          .card-image-holder {
            max-width: 580px;
            margin: auto;

            img {
              display: block;
              max-width: 100%;
              @media (min-width: 768px) {
                max-width: 50%;
                margin: 0 auto;
              }
              @media (min-width: 992px) {
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .trusted-seals {
    padding: 3rem 0 0 0;
    @media (min-width:768px) {
      padding: 5rem 0 0 0;
    }
    .container {
      .row {
        @media (min-width:992px) {
          align-items: center;
        }
        .trusted-seal-headline {
          flex: 0 0 auto;
          width: 100%;
          display: none;
          @media (min-width:992px) {
            display: block;
            width: 30%;
          }
          h2 {
            font-size: calc(1.20rem + 1.2vw);
            line-height: 1.25;
            color: var(--black);
            @media (min-width: 992px) {
              font-size: 35px;
            }
          }
        }
        .trusted-seal-wrapper {
          flex: 0 0 auto;
          width: 100%;
          text-align: center;
          @media (min-width:992px) {
            width: 70%;
            padding: 0 1rem;
          }
        }
      }
    }
  }
  .claims {
    padding: 3rem 0 3rem 0;
    @media (min-width:768px) {
      padding: 5rem 0 ;
    }
    .container {
      .row {
        .claim-headline-wrapper {
          @media (min-width:992px) {
            max-width: 100%;
            margin-bottom: 5rem;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
          }
          .claim-headline-left {
            @media (min-width:992px) {
              max-width: 50%;
              padding: 0 1rem;
            }
            h2 {
              font-size: calc(1.35rem + 1.2vw);
              line-height: 1.25;
              margin-bottom: 1.25rem;
              color: var(--black);
              @media (min-width:992px) {
                margin-bottom: 0;
              }
            }
          }
          .claim-headline-right {
            @media (min-width:992px) {
              max-width: 50%;
              padding: 0 1rem;
            }
          }
        }
        .item-container {
          padding: 2rem 0 0 0;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          @media (min-width: 768px) {
            justify-content: space-between;
          }

          @media (min-width:992px) {
            padding: 0 0 2rem 0;
          }

          .claim-item {
            width: 100%;
            margin-bottom: 1rem;

            @media (min-width: 768px) {
              width: 48%;
            }
            @media (min-width: 992px) {
              width: 25%;
              padding: 0 1rem;
            }
            .bignumber {
              line-height: 1.5625rem;
              font-size: 1.5rem;
              font-weight: 600;
              text-transform: capitalize;
              margin-bottom: .75rem;
              color: var(--accent);
              @media(min-width:992px) {
                font-size: 2rem;
              }
            }

            .smalltext {
              width: 80%;
              @media (min-width: 768px) {
                width: 100%;
              }
              p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  .section1 {
    padding: 3rem 0 0 0;
    background: var(--bg-color2);
    @media (min-width:768px) {
      padding: 5rem 0 ;
    }
    .container {
      .row {
        align-items: center;

        .section-1-content {
          flex: 0 0 auto;
          width: 100%;
          margin: 0 auto;

          @media (min-width:992px) {
            width: 50%;
            margin: 0;
          }

          h1 {
            font-size: calc(1.35rem + 1.2vw);
            line-height: 1.25;
            margin-bottom: 1.25rem;
            color: var(--white);
          }

          p {
            color: var(--white);
          }
        }

        .item-benefits {
          margin: 2rem 0;
          width: 100%;

          ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            @media (min-width:768px) {
              row-gap: 1rem;
              column-gap: 1rem;
            }

            li {
              flex: 0 0 auto;
              width: 100%;
              margin-bottom: 1rem;

              @media (min-width:768px) {
                width: 48%;
              }

              @media (min-width:992px) {
                width: 23%;
              }

              .benefit {
                border-radius: 10px;
                position: relative;
                border: #9FCEFD 1px solid;
                background-color: #fff;
                @media (min-width:768px) {
                  min-height: 466px;
                }
                .benefitImage {
                  position: relative;
                  text-align: center;
                  img {
                    width: 100%;
                    max-width: 200px;
                    margin: 0 auto;
                  }
                }

                .benefitHeadline {
                  padding: 45px 25px;
                  h3 {
                    line-height: 1.5625rem;
                    font-size: 1.5rem;
                    color: var(--accent);
                    margin-bottom: 1.25rem;
                  }

                  p {
                    font-size: 16px;
                    line-height: 25.6px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .testimonial {
    padding: 3rem 0;
    @media (min-width:768px) {
      padding: 5rem 0 ;
    }

    .container {
      .row {
        .testimonial-headline {
          flex: 0 0 auto;
          width: 100%;

          @media (min-width:992px) {
            width: 50%;
            margin: 0;
          }

          h1 {
            font-size: calc(1.35rem + 1.2vw);
            line-height: 1.25;
            margin-bottom: 1.25rem;
            color: var(--black);

            @media (min-width:992px) {
              font-size: 45px;
              line-height: 54px;
            }
          }
        }

        .testimonial-content-holder {
          flex: 0 0 auto;
          width: 100%;
          text-align: center;

          img {
            margin: 0 auto;
          }

          @media (min-width:992px) {
            width: 50%;
          }
        }

        .testimonial-list {
          display: flex;
          flex-wrap: wrap;

          @media (min-width:992px) {
            column-gap: 1rem;
          }

          .testimonial-item {
            margin-bottom: 1rem;
            flex: 0 0 auto;
            width: 100%;
            margin-bottom: 1rem;

            @media (min-width:992px) {
              width: 32%;
            }

            .testimonial-inner {
              padding: 30px 30px 30px 30px;
              text-align: center;
              box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0);
              border-radius: 10px;
              border: 1px solid #E7EAEC;
              background-color: var(--white);
              position: relative;
              margin-bottom: 90px;

              @media (min-width:768px) {
                width: 50%;
                margin-left: auto;
                margin-right: auto;
              }

              @media (min-width:992px) {
                width: 100%;
              }

              .testimonial-top {
                position: absolute;
                left: 0;
                bottom: -90px;
                width: 100%;
                text-align: center;
                z-index: 5;

                .testimonial-left {
                  img {
                    max-width: 100px;
                    border-radius: 100%;
                  }
                }
              }

              .testimonial-bottom {
                h3 {
                  margin-bottom: 0.5rem;
                }

                p {
                  margin-bottom: 10px;
                }

                img {
                  margin-bottom: 20px;
                }
              }
            }
          }
        }
      }
    }
  }

  .footer {
    padding: 3rem 0 6rem 0;
    .top-footer {
      p {
        font-size: .875rem;
        color: rgba($color: #000000, $alpha: 0.5);
      }
    }

    .bottom-footer {
      .footer-navigation {
        flex: 0 0 auto;
        width: 100%;
        margin: 1rem 0;
        display: flex;
        flex-wrap: wrap;
        text-align: center;

        @media(min-width:992px) {
          justify-content: space-between;
        }

        .footer-copyright {
          flex: 0 0 auto;
          width: 100%;

          @media(min-width:992px) {
            width: auto;
          }
        }

        .footer-navigation-wrapper {
          flex: 0 0 auto;
          width: 100%;

          @media(min-width:992px) {
            width: auto;
          }

          ul {
            display: flex;
            justify-content: center;
            align-items: center;

            li {
              &:nth-child(1) {
                &::after {
                  content: '|';
                  margin: 0 1rem;
                }
              }

              a {
                text-decoration: none;
                color: var(--black);
              }
            }
          }
        }
      }
    }
  }

  .policy-wrapper {
    .policy-title {
      padding: 2rem 0;

      h1 {
        font-size: calc(1.375rem + 1.5vw);

        @media (min-width:1200px) {
          font-size: 2.5rem;
          ;
        }
      }

      flex: 0 0 auto;
      width: 100%;
      border-bottom: 1px solid gray;
      margin-bottom: 2rem;
    }

    .policy-paragraph {
      padding-bottom: 4rem;

      p {
        margin-bottom: .5rem;
      }

      h2 {
        font-size: calc(1.325rem + .9vw);
        margin: 1rem 0;

        @media(min-width:1200px) {
          font-size: 2rem;
        }
      }

      h3 {
        font-size: calc(1.3rem + .6vw);
        margin-bottom: 1rem;

        @media(min-width:1200px) {
          font-size: 1.75rem;
        }
      }

      h3 {
        font-size: calc(1.275rem + .3vw);

        @media(min-width:1200px) {
          font-size: 1.5rem;
        }
      }

      ul {
        list-style-type: disc;
        margin-bottom: .5rem;
      }
    }
  }
}